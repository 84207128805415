import React, { useContext, useState } from "react";
import Form from "../components/form/elements/Form";
import InputField from "../components/form/elements/InputField";
import Button from "../components/ui/Button";
import auth from "../services/authServices";
import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";
import http from "../services/httpServices";
import AuthContext from "../context/AuthContext";
import {
    isCommunityVoiceRecordingEnabled,
    isWhatsAppAudioPlayerStyle,
} from "../services/defaultSettings";
import AudioPlayer from "../components/ui/AudioPlayer";
import { Icon } from "@iconify/react/dist/iconify.js";

const initialState = {
    description: "",
    file: "",
};

const renderAudioPlayer = (setData, data) => {
    return (
        <div className="w-full p-2 rounded-lg smooth flex items-center justify-between space-x-reverse space-x-2">
            {isWhatsAppAudioPlayerStyle ? (
                <AudioPlayer setData={setData} audioSrc={URL.createObjectURL(data.audio_source)} />
            ) : (
                <>
                    <audio
                        controls
                        src={URL.createObjectURL(data.audio_source)}
                        className="w-full bg-gray-100 dark:bg-gray-800 smooth"
                    >
                        Your browser does not support the audio element.
                    </audio>
                    <button
                        onClick={() => {
                            setData((prevData) => ({
                                ...prevData,
                                audio_source: "",
                            }));
                        }}
                        className="text-red-500 smooth hover:text-red-800 smooth p-2 rounded-full bg-gray-300 dark:bg-gray-700"
                    >
                        <Icon width="18" icon="weui:delete-on-filled" className="w-6 h-6" />
                    </button>
                </>
            )}
        </div>
    );
};
const CommunityCreateCommentForm = ({ category, posts, setPosts, post }) => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isShownAddComment, setIsShownAddComment] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    const { user, adminToken } = useContext(AuthContext);
    const handleToggleRecording = () => setIsRecording(!isRecording);

    const handleSubmit = async (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, async () => {
            setIsLoading(true);
            const token = auth.getToken();
            let config = auth.getAuthConfig(token);
            let toSendData = data;
            if (adminToken) {
                config = auth.getAuthConfig(adminToken);
                toSendData = { ...toSendData, user_submitting_by_email: user.email };
            }
            // const adminConfig = auth.getAuthConfig(tokenAdmin);
            try {
                const formData = getFormData(toSendData);
                let { data: response } = await http.post(
                    `/api/community_categories/${category["id"]}/posts/${post["id"]}/comments`,
                    formData,
                    config
                );
                response = {
                    ...response,
                    user: { full_name: user.full_name },
                };
                setPosts(
                    posts.map((item) => {
                        if (item.id !== post.id) {
                            return item;
                        }
                        item.community_comments_count++;
                        item.community_comments = [response, ...item.community_comments];
                        return item;
                    })
                );
                setIsLoading(false);
                setIsShownAddComment(false);
                setData(initialState);
            } catch ({ response }) {
                handleFormErrors(response, setIsLoading, setErrors, false);
            }
        });
    };
    return (
        <>
            {!adminToken &&
            (!category.is_user_able_to_comment || category.is_closed || post.is_closed) ? (
                ""
            ) : isShownAddComment ? (
                <div className="py-2">
                    <Form
                        className="flex-center-both w-full max-w-xl mx-auto p-5 rounded-md bg-third-container clr-text-primary smooth shadow-medium"
                        onSubmit={handleSubmit}
                    >
                        <div className="w-full space-y-4">
                            <InputField
                                id="description"
                                type="textarea"
                                placeholder="اكتب تعليق"
                                setData={setData}
                                data={data}
                                errors={errors}
                                onChange={handleInputChange}
                            />

                            <div className="flex-center-both w-full gap-3  items-center">
                                {adminToken && isCommunityVoiceRecordingEnabled && (
                                    <>
                                        <InputField
                                            id="audio_source"
                                            type="custom_audio"
                                            placeholder="تعليق صوتي"
                                            setData={setData}
                                            data={data}
                                            errors={errors}
                                            onChange={handleInputChange}
                                            handleToggleRecording={handleToggleRecording}
                                        />
                                        {data.audio_source ? (
                                            renderAudioPlayer(setData, data)
                                        ) : (
                                            <>
                                                <div className="">هل تريد تسجيل ريكورد؟</div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="space-y-2">
                                <div className="">هل تريد ارفاق صورة؟</div>
                                <InputField
                                    id="image"
                                    type="file"
                                    placeholder="ارفق صورة"
                                    isRequired={false}
                                    inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                    setData={setData}
                                    data={data}
                                    errors={errors}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="flex-center-both">
                                <Button color="violet" isLoading={isLoading}>
                                    {"ارسال التعليق"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            ) : (
                <div className="flex-center-both pt-2 -mb-6">
                    <Button color="violet" onClick={() => setIsShownAddComment(!isShownAddComment)}>
                        اترك تعليقًا !
                    </Button>
                </div>
            )}
        </>
    );
};

export default CommunityCreateCommentForm;
