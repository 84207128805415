import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import auth from "../services/authServices";
import http from "../services/httpServices";
import Categorie from "../sections/home/CoursesSection/Categorie";
import modal from "../services/modalServices";
import LoadingIcon from "../components/ui/LoadingIcon";
import Container from "../components/ui/Container";
import HeaderSection from "../components/ui/HeaderSection";
import page from "../services/pageServices";
import localStorageService from "../services/localStorageService";

const AvailableToAssignCoursesStore = () => {
    let { unassignedSubscriptions, setUnassignedSubscriptions } = useContext(AuthContext);
    let authUser = auth.getUser();
    const navigate = useNavigate();
    const [assignableCourses, setAssignableCourses] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const getAssignableCourses = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`/api/courses/available_to_assign`, config);
        setAssignableCourses(data);
    };
    const onAssignCourseClick = async (course_id, name) => {
        setIsLoading(true);
        modal.message({
            title: "رسالة تأكيد",
            text: `هل انت متأكد من الاشتراك في ${name} ؟`,
            icon: "info",
            buttons: {
                confirm: "الاشتراك",
                cancel: "إلغاء",
            },
            callback: async (e) => {
                if (e && e !== "cancel") {
                    const token = auth.getToken();
                    const config = auth.getAuthConfig(token);

                    // eslint-disable-next-line no-unused-vars
                    const { data } = await http.post(
                        `/api/user/assign_unassigned_subscription`,
                        { course_id },
                        config
                    );
                    const unassignedSubscriptionsKey = `unassignedSubscriptions_${authUser?.email}`;
                    localStorageService.remove(unassignedSubscriptionsKey);
                    if (unassignedSubscriptions && unassignedSubscriptions > 0) {
                        localStorageService.set(
                            unassignedSubscriptionsKey,
                            unassignedSubscriptions - 1,
                            1800000
                        );
                    }
                    modal.message({
                        title: "عملية ناجحة",
                        text: `تم الاشتراك بـ ${name} بنجاح !`,
                        icon: "success",
                        callback: () => {
                            setUnassignedSubscriptions(unassignedSubscriptions - 1);
                            auth.setavailableCoursesToAssignStoreSeen(0);
                            localStorageService.remove(`globalNotifications-${authUser?.email}`);
                            localStorageService.remove(`subscribedCourses_${authUser?.email}`);
                            navigate(`/course/${course_id}`);
                        },
                    });
                    // setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            },
        });
    };

    useEffect(() => {
        if (unassignedSubscriptions < 0) {
            return navigate("/");
        }
        auth.setavailableCoursesToAssignStoreSeen(true);
        getAssignableCourses();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unassignedSubscriptions]);

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle("استخدم رصيدك من الإشتراكات غير المخصصه!");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    return (
        <>
            <div className="smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <Container className="py-10 pb-10 space-y-0">
                    <HeaderSection></HeaderSection>
                </Container>
            </div>
            <div className="pb-10">
                <div className="flex-center-both flex-col space-y-5">
                    <div className="flex-center-both bg-rose-500 clr-white rounded-full px-10 py-3 font-h2 font-w-bold">
                        يمكنك استخدام رصيدك من الاشتراكات غير المخصصه هنا !
                    </div>
                    <div className="space-y-2 flex-center-both flex-col text-center">
                        اختر المحاضرة او الكورس الذي تريد الاشتراك به و سيتم خصمها من رصيدك من
                        الإشتراكات غير المخصصه
                        <br />
                        <br />
                        اضغط علي الكورس او المحاضرة التي تريد الاشتراك بها
                    </div>
                </div>
                {isLoading ? (
                    <>
                        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 pt-40">
                            <span className="flex-center-both space-x-3 space-x-reverse">
                                <LoadingIcon
                                    className={"font-h1 text-teal-600 dark:text-teal-400"}
                                />
                                <span>برجاء الانتظار قليلًا</span>
                            </span>
                        </div>
                    </>
                ) : (
                    <Categorie
                        titleFirst="المحاضرات و"
                        titleLast="الكورسات"
                        onAssignCourseClick={onAssignCourseClick}
                        coursesList={assignableCourses}
                        isToAssignCourses={true}
                    />
                )}
            </div>
        </>
    );
};

export default AvailableToAssignCoursesStore;
