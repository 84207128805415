import React, { useEffect, useState } from "react";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { rewardStatusOption } from "../../utils/ar";
import { printFullDateTime } from "../../utils/time";
import { isObjectEmpty } from "../../utils/objects";
import localStorageService from "../../services/localStorageService";
import topFanBadge from "../../assets/Updated-imagery/reunion_16067188.png";
const UserReward = () => {
    const [reward, setReward] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [className, setClassName] = useState("");
    const fetchReward = async () => {
        setLoading(true);
        const CACHE_DURATION = 30 * 60 * 1000;
        const authUser = auth.getUser() || {};
        let cachedRewardKey;

        if (!isObjectEmpty(authUser) && authUser?.email) {
            cachedRewardKey = `reward_${authUser.email}`;
        }

        try {
            const cachedReward = localStorageService.get(cachedRewardKey);
            if (cachedReward) {
                setReward(cachedReward);
                setLoading(false);
                return;
            }
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get("/api/spinning_wheel/user_reward", config);
            isObjectEmpty(data) && setClassName("hidden");
            !isObjectEmpty(data) && localStorageService.set(cachedRewardKey, data, CACHE_DURATION);
            setReward(data);
            setLoading(false);
        } catch (err) {
            setError("حدث خطأ أثناء تحميل الجائزة");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReward();
    }, []);

    if (loading) {
        return (
            <div className="flex-center-both">
                <LoadingIcon />
                <span>يتم تحميل الجائزة...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="py-3 px-5 shadow-sm rounded-md bg-outer-container smooth  text-center text-red-500">
                {error}
            </div>
        );
    }

    return (
        <div
            className={`p-6 bg-outer-container overflow-hidden smooth rounded-xl shadow-md max-w-md mx-auto ${className}`}
        >
            <h2 className="font-h1 text-rose-500 mb-4 text-center">مبروك يا بطل</h2>

            <div className="bg-inner-container smooth p-5 rounded-lg shadow-lg text-center">
                <h2 className="font-h2 text-rose-500 mb-2 text-center">جائزتك</h2>
                <p className="text-2xl font-bold text-yellow-800 dark:text-yellow-400 smooth mb-4">
                    {reward.reward_name === "Top Fan" && (
                        <>
                            <img
                                src={topFanBadge}
                                alt="topFanBadge"
                                className="w-6 h-6 inline-block"
                            />
                        </>
                    )}
                    {reward.reward_name}
                    {reward.reward_name === "Top Fan" && (
                        <>
                            <img
                                src={topFanBadge}
                                alt="topFanBadge"
                                className="w-6 h-6 inline-block"
                            />
                        </>
                    )}
                </p>
                {reward.reward_name !== "Top Fan" && (
                    <>
                        <p className="text-gray-600 dark:text-gray-300 smooth mb-2">
                            <span className="font-semibold text-gray-800 dark:text-gray-200 smooth">
                                حالة الجائزة:
                            </span>{" "}
                            {reward.reward_status !== "new_reward"
                                ? rewardStatusOption.find(
                                      (option) => option.value === reward.reward_status
                                  )?.text
                                : "سيتم التواصل معك في اقرب وقت ❤️"}
                        </p>
                        <p className="text-gray-600 dark:text-gray-300 smooth">
                            <span className="font-semibold text-gray-800 dark:text-gray-200 smooth">
                                تاريخ الحصول علي الجائزة:
                            </span>{" "}
                            {printFullDateTime(reward.reward_date)}
                        </p>
                    </>
                )}
            </div>

            {reward.coupon && (
                <div className="mt-6 bg-inner-container smooth group hover:bg-yellow-400 p-5 rounded-lg shadow-lg text-center">
                    <h3 className="font-bold text-yellow-500 group-hover:text-rose-600 mb-3">
                        الكوبون
                    </h3>
                    <p className="text-gray-800  dark:text-gray-300 group-hover:text-black smooth mb-2">
                        <span className="font-semibold  text-rose-500">اسم الكوبون:</span>{" "}
                        {reward.coupon.name}
                    </p>
                    <p className="text-gray-800  group-hover:text-black  dark:text-gray-300 smooth">
                        <span className="font-semibold   text-rose-500">خصم الكوبون:</span>{" "}
                        {reward.coupon.discount}%
                    </p>
                </div>
            )}
            {reward.fellow_coupon && (
                <div className="mt-6 bg-inner-container smooth hover:bg-yellow-400 p-5 rounded-lg shadow-lg text-center">
                    <h3 className="font-bold text-yellow-500 group-hover:text-rose-600 mb-3">
                        كوبون صاحبك
                    </h3>
                    <p className="text-gray-800 dark:text-gray-300  group-hover:text-black smooth mb-2">
                        <span className="font-semibold text-gray-800">اسم الكوبون:</span>{" "}
                        {reward.fellow_coupon.name}
                    </p>
                    <p className="text-gray-800">
                        <span className="font-semibold dark:text-gray-300 group-hover:text-black  text-gray-800">
                            خصم الكوبون:
                        </span>{" "}
                        {reward.fellow_coupon.discount}%
                    </p>
                </div>
            )}
        </div>
    );
};

export default UserReward;
