import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import { Link } from "react-router-dom";
import auth from "../../services/authServices";
import { printIdOfOptions, printUnit, rewardStatusOption } from "../../utils/ar";
import modal from "../../services/modalServices";
import modal2 from "../../services/modal2Services";

const LuckyUsersTable = () => {
    const [editableData, setEditableData] = useState(null);
    const [isToEditData, setIsToEditData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const rewardTypeOption = [
        { value: "non_dynamic_rewards", text: "جائزة مخصصة" },
        { value: "wallet", text: "فائز بمبلغ بالمحفظة" },
        { value: "coupons", text: "فائز بكوبون خصم" },
    ];

    const showChangingInputModal = (row, text, row_key) => {
        const inputOptions = rewardStatusOption.reduce((options, reward) => {
            options[reward.value] = reward.text;
            return options;
        }, {});
        console.log(inputOptions);
        modal2.message({
            title: "هل انت متأكد؟",
            input: row_key === "reward_status" ? "select" : "text",
            inputOptions: row_key === "reward_status" ? inputOptions : null,
            inputLabel: text,
            inputValue: row_key === "reward_status" ? row.reward_status : row.reward_admin_note,
            inputValidator: (value) => {
                if (!value) {
                    return "يجب عليك كتابه القيمة";
                }
            },
            icon: "warning",
            confirmButtonText: "تنفيذ",
            cancelButtonText: "إلغاء",
            showCancelButton: true,
            callback: async (e) => {
                if (e.isConfirmed) {
                    handleRewardStatusChange(row.id, e.value, row_key);
                }
            },
        });
    };
    const handleRewardStatusChange = async (id, row_value, row_key) => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        setIsLoading(true);
        try {
            const dataToSend = { row_key, row_value };
            const { data: response } = await http.post(
                `/api/spinning_wheel_reward_logs/${id}`,
                dataToSend,
                config
            );

            modal.message({
                title: "تم تحديث بيانات الجائزة",
                message: response.message,
            });
            let updated_row = editableData.map((row) => {
                if (row.id === id) {
                    return { ...row, [row_key]: row_value };
                }
                return row;
            });
            setEditableData(updated_row);
            setIsToEditData(true);
            setIsLoading(false);
        } catch (error) {
            modal.message({
                title: "حدث خطأ",
                message: "لم يتم تغيير حالة الجائزة",
                icon: "error",
            });
            setEditableData([...editableData]);
            setIsToEditData(true);
            setIsLoading(false);
        }
    };
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    row.user.full_name
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            // sortable: true,
            // sortField: "user.full_name",
        },
        {
            name: "رقم هاتف الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.user.id}`}
                    >
                        0{row.user.phone}
                    </Link>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
        },
        {
            name: "رقم هاتف ولى الأمر",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <>0{row.user.father_phone}</>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
        },
    ];
    columns = [
        ...columns,
        {
            name: "اسم الجائزة",
            reorder: true,
            selector: (row) => row.reward_name,
            sortable: true,
            sortField: "reward_name",
        },
        {
            name: "نوع الجائزة",
            reorder: true,
            selector: (row) =>
                rewardTypeOption.find((option) => option.value === row.reward_type).text,
            sortable: true,
            sortField: "reward_type",
        },
    ];
    columns = [
        ...columns,
        {
            name: "حالة الجائزة",
            reorder: true,
            selector: (row) => {
                let reward_status = rewardStatusOption.find(
                    (option) => option.value === row.reward_status
                ).text;
                return (
                    <span className="flex flex-col flex-center-both space-y-2">
                        <span className="inline-block">{reward_status}</span>
                        {row.reward_type === "non_dynamic_rewards" && (
                            <div>
                                <Button
                                    color="yellow"
                                    className="pb-0.5 px-2"
                                    isLoading={isLoading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        showChangingInputModal(
                                            row,
                                            "تعديل حالة استلام الجائزة",
                                            "reward_status"
                                        );
                                    }}
                                >
                                    تعديل
                                </Button>
                            </div>
                        )}
                    </span>
                );
            },
            sortable: true,
            sortField: "reward_status",
        },
    ];
    columns = [
        ...columns,

        {
            name: "ملاحظة المسؤول عن الجائزة",
            reorder: true,
            selector: (row) => (
                <span className="flex flex-col flex-center-both space-y-2">
                    <span className="inline-block">{row.reward_admin_note || "---"}</span>
                    {row.reward_type === "non_dynamic_rewards" && (
                        <div>
                            <Button
                                color="yellow"
                                className="pb-0.5 px-2"
                                isLoading={isLoading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    showChangingInputModal(
                                        row,
                                        "تعديل ملاحظة المسؤول",
                                        "reward_admin_note"
                                    );
                                }}
                            >
                                تعديل
                            </Button>
                        </div>
                    )}
                </span>
            ),
            sortable: false,
        },
        {
            name: "سجل الكوبونات",
            reorder: true,
            selector: (row) => {
                if (!row.fellow_coupon_id) {
                    return row.coupon ? row.coupon.name : "---";
                }
                return row.coupon && row.fellow_coupon ? (
                    <div className="flex-center-both flex-col gap-1">
                        <span className="text-indigo-500">{row.coupon.name}</span>
                        ---
                        <span className="text-rose-500">{row.fellow_coupon.name}</span>
                    </div>
                ) : (
                    "---"
                );
            },
            sortable: true,
            sortField: "coupon_id",
        },
        {
            name: "سجل المحفظة",
            reorder: true,
            selector: (row) =>
                row.wallet_record ? (
                    <div>
                        <div>بعد الفوز: {printUnit(row.wallet_record.updated_balance, "جنيه")}</div>
                    </div>
                ) : (
                    "---"
                ),
            sortable: true,
            sortField: "wallet_record_id",
        },
    ];

    columns = [
        ...columns,
        {
            name: "تاريخ انشاء الجائزة",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const initialState = {
        phone: "",
        reward_type: "non_dynamic_rewards",
        reward_name: "",
        reward_admin_note: "",
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);
    const [rewardsOptions, setRewardsOptions] = useState([]);

    const getRewardNames = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(
            `/api/spinning_wheel_reward_logs/rewards/options`,
            config
        );

        setRewardsOptions(response);
    };

    useEffect(() => {
        getRewardNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="reward_admin_note"
                        placeholder="ملاحظة المسؤول عن الجائزة"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="reward_name"
                        placeholder="اسم الجائزة"
                        type="select"
                        options={[
                            {
                                value: "all_rewards",
                                text: "عرض كل الجوائز",
                            },
                            ...rewardsOptions,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="reward_type"
                        placeholder="نوع الجائزة"
                        type="select"
                        options={[
                            {
                                value: "all_rewards",
                                text: "عرض كل الجوائز",
                            },
                            {
                                value: "non_dynamic_rewards",
                                text: "جائزة مخصصة",
                            },
                            // {
                            //     value: "top_fan",
                            //     text: "توب فانز",
                            // },
                            {
                                value: "wallet",
                                text: "الفائزين بمبلغ بالمحفظة",
                            },
                            {
                                value: "coupons",
                                text: "الفائزين بكوبون خصم",
                            },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/spinning_wheel_reward_logs/paginate`}
                    isToEditData={isToEditData}
                    setIsToEditData={setIsToEditData}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default LuckyUsersTable;
