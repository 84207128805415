import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import { ReactComponent as Million } from "../../../assets/Updated-imagery/MillionGohary.svg";
import SpinningWheel from "../../../components/ui/SpinningWheel";
import giftPrize from "../../../assets/Updated-imagery/gift.png";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import AuthContext from "../../../context/AuthContext";

import localStorageService from "../../../services/localStorageService";
import { isObjectEmpty } from "../../../utils/objects";

import snowPattern from "../../../assets/Updated-imagery/Snow_Flakes.png";

import { ReactComponent as SnowBg } from "../../../assets/Updated-imagery/spinWheelSnow.svg";

const MySwal = withReactContent(Swal);

const SpinningWheelModal = ({ className }) => {
    const [selectedPrize, setSelectedPrize] = useState(null);
    const [rewards, setRewards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedReward, setSelectedReward] = useState(null);
    let { token: authToken, user, setCurrentBalance, fetchWinners } = useContext(AuthContext);

    const fetchRewards = async () => {
        setIsLoading(true);
        const CACHE_DURATION = 24 * 60 * 60 * 1000;
        let authUser = auth.getUser() || {};
        let cached_rewards_key;
        if (!isObjectEmpty(authUser) && authUser?.email) {
            cached_rewards_key = `spin_wheel_rewards_${authUser?.email}`;
        }
        try {
            const cachedRewards = localStorageService.get(cached_rewards_key);
            if (cachedRewards) {
                setRewards(cachedRewards);
                setIsLoading(false);
                return;
            }
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get("/api/spinning_wheel/rewards", config);
            const rewardsArray = Object.values(data.rewards || {});
            const rewardsData = rewardsArray.map((reward) => reward.reward_name);
            if (rewardsData.length > 0) {
                localStorageService.set(cached_rewards_key, rewardsData, CACHE_DURATION);
            }
            setRewards(rewardsData);
            setIsLoading(false);
        } catch (err) {
            console.error("Failed to fetch rewards:", err);
            setError("تعذر تحميل الجوائز");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (authToken) fetchRewards();
    }, [authToken]);

    const openSpinningWheelModal = () => {
        if (isLoading) {
            MySwal.fire({
                title: "تحميل الجوائز",
                text: "جاري تحميل الجوائز، يرجى الانتظار...",
                icon: "info",
            });
            return;
        }

        if (error) {
            MySwal.fire({
                title: "خطأ",
                text: error,
                icon: "error",
            });
            return;
        }
        MySwal.fire({
            html: (
                <div className="relative bg-slate-100">
                    <div
                        className="absolute inset-0 w-full h-full opacity-100 -z-0"
                        style={{
                            backgroundImage: `url(${snowPattern})`,
                            backgroundSize: "contain",
                            backgroundPosition: "left left",
                            backgroundRepeat: "repeat",
                        }}
                    ></div>
                    <SnowBg className="absolute top-0 -bottom-20 left-[40%] w-[80%] h-full opacity-50 z-5" />
                    <section className="flex flex-col md:flex-row relative z-10 items-center py-8 px-4 md:px-20 min-h-[80vh] rounded-lg">
                        <div className="basis-full md:basis-1/2 text-center relative md:text-right">
                            {/* Background Animated SVG */}
                            {/* <Million className="absolute inset-0 blur-[1px] animate-pulse" /> */}

                            {/* Heading */}
                            <h2 className="text-3xl font-bold mb-4">
                                حبايبنا في <span className="text-indigo-500">FULLMARK !</span>
                                <br />
                                <span className="text-xl">
                                    خلينا نحتفل{" "}
                                    <span className="text-indigo-500 font-semibold">
                                        برأس السنة الجديدة
                                    </span>{" "}
                                    مع بعض! 🎉
                                </span>
                            </h2>

                            {/* Description */}
                            <p className="text-lg mb-4 w-3/4 mx-auto md:mx-0">
                                سنة جديدة، فرص جديدة، وجوائز تحفة لطلابنا! 💫 دلوقتي، تقدر تلف
                                العجلة وتجرب حظك علشان تكسب. هابي نيو يير! 🎊
                            </p>
                            {/* Selected Prize Display
                        <div className="mt-6 p-4 border-2 w-3/4 md:w-1/2 mx-auto relative border-rose-500 rounded-lg text-center">
                            <h3 className="text-xl font-semibold text-rose-500">
                                الجايزة اللي كسبتها:
                            </h3>
                            <p className="text-2xl font-bold mt-2">
                                {selectedPrize || "استفيد بجايزتك فوراً واستمتع بالتعليم!"}
                            </p>
                            <p className="mt-6 text-center text-sm text-gray-600">
                                🎉 حظ سعيد، وشكراً إنك جزء من رحلتنا للمليون! 🎉
                            </p>
                        </div> */}
                        </div>

                        {/* Spinning Wheel Section */}
                        <div className="basis-full md:basis-1/2 flex justify-center items-center">
                            <SpinningWheel
                                setCurrentBalance={setCurrentBalance}
                                fetchWinners={fetchWinners}
                                items={rewards}
                                onSelectItem={(reward) => setSelectedReward(reward)}
                            />
                        </div>
                    </section>
                </div>
            ),
            showConfirmButton: false,
            showCloseButton: true,
            width: "80%",
        });
    };

    return (
        <div className={`text-center ${className}`}>
            <div className="p-2 cursor-pointer relative group  " onClick={openSpinningWheelModal}>
                <div className="p-1 -z-10 absolute inset-0 smooth group-hover:animate-spin cursor-pointer outline-dashed rounded-full outline-offset-2 outline-1 hover:shadow-sm smooth outline-rose-500" />

                <div className="relative">
                    <div className="w-2 h-2 absolute -top-0 -right-1 bg-green-500 rounded-full animate-pulse" />

                    <img src={giftPrize} alt="Gift Prize" className="w-6 h-6 inline-block" />
                </div>
            </div>
        </div>
    );
};

export default SpinningWheelModal;
